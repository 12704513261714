import React, { useState } from 'react';
// import { MediaOverlay } from "@react-md/media";
import { IMG_BASE_PATH } from "../../constants";
import { TextField } from "@react-md/form";

export function AccessDenied(
  {
    logic
  }
) {
  return (
    // <MediaOverlay
    //   position={'absolute-center'}
    //   style={{
    //     backgroundColor: 'transparent',
    //     // textAlign: 'center',
    //     '--rmd-form-text-border-color': '#fff',
    //   }}
    // >
      <EnterToken logic={logic} />
    // </MediaOverlay>
  )
}

const EnterToken = ({ logic }) => {
  const [ value, setValue ] = useState('');
  const codes = logic.props.authCodes;
  return (
    <TextField
      style={{ minWidth: '250px' }}
      theme={'outline'}
      // stretch={true}
      label={'Enter Code'}
      aria-label={'Enter Access Code'}
      dense={true}
      leftChildren={(
        <img
          src={`${IMG_BASE_PATH}/icons/icon-48x48.png`}
          alt={'Attraction Marketing.com logo'}
          width={'24px'}
          height={'24px'}
        />
      )}
      isLeftAddon={true}
      value={value}
      onChange={e => {
        let value = e.target.value;
        setValue(value);
        if (!value) return;
        value = value.replace(/\s/g, '').toLowerCase();
        if (codes[value]) {
          logic.getSession().setAuthCode(value, 'overview');
        }
      }}
    />
  )
}
