import React from 'react';
import { Video } from "../../video";
import { Button } from "@react-md/button";
import { FontIcon, TextIconSpacing } from "@react-md/icon";
import { IMG_BASE_PATH } from "../../constants";
import { Fieldset } from "@react-md/form";

export const Overview = ({ logic }) => {
  // const videoUrl = logic.props.amfOverviewVideoUrl;
  const videoUrl = 'https://dev-39.wistia.com/medias/25fvm1drey';
  return (
    <div style={{
      textAlign: 'center',
      '--rmd-button-color': '#fff',
      '--rmd-button-outline': '#fff',
    }}>
      <div
        style={{
          width: '75vw',
          maxWidth: '800px',
          boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
      }}>
        <Video
          light={!!!videoUrl}
          preview={`${IMG_BASE_PATH}/vid/video_overview_preview.webp`}
          url={videoUrl}
          name={'App Overview'}
          controls={true}
        />
      </div>
      {/*<Button*/}
      {/*  theme={'clear'}*/}
      {/*  themeType={'flat'}*/}
      {/*  style={{*/}
      {/*    // position: 'fixed',*/}
      {/*    // top: '12px',*/}
      {/*    // right: '300px',*/}
      {/*    // zIndex: 10000,*/}
      {/*    marginTop: '2rem',*/}
      {/*    color: '#fff'*/}
      {/*  }}*/}
      {/*  disabled={true}*/}
      {/*>*/}
      {/*  <TextIconSpacing*/}
      {/*    icon={<FontIcon>phone</FontIcon>}*/}
      {/*  >*/}
      {/*    702-885-5089*/}
      {/*  </TextIconSpacing>*/}
      {/*</Button>*/}
      <Button
        themeType={'outline'}
        style={{
          // position: 'fixed',
          // top: '12px',
          // right: '24px',
          // zIndex: 10000,
          marginTop: '2rem',
        }}
        onClick={() => {
          const sess = logic.getSession();
          sess.setState({ meta: { ...sess.meta, auth: 'app' }});
        }}
      >
        <TextIconSpacing
          icon={(
            <img
              src={`${IMG_BASE_PATH}/icons/icon-48x48.png`}
              alt={'Attraction Marketing.com'}
              width={'16px'}
              height={'16px'}
            />
          )}
        >
          <TextIconSpacing
            icon={<FontIcon>arrow_forward</FontIcon>}
            iconAfter={true}
          >
            Access App
          </TextIconSpacing>
        </TextIconSpacing>
      </Button>
      <Fieldset
        legend={'Scripts For You'}
        className={'dark-theme'}
        style={{
          marginTop: '2rem',
          border: '1px dashed gray',
          padding: '1rem',
          maxWidth: '400px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <div style={{ display: 'inline', textAlign: 'left' }}>
          <a
            href={'/static/scripts/amfCheckoutRestore.js'}
            download={true}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <Button themeType={'flat'}>
              <TextIconSpacing icon={<FontIcon>download</FontIcon>}>
                Checkout
              </TextIconSpacing>
            </Button>
          </a>
        </div>
        <div style={{ display: 'inline', textAlign: 'left' }}>
          <a
            href={'/static/scripts/amfCheckoutRestore.min.js'}
            download={true}
            style={{
              textDecoration: 'none',
              color: 'unset'
            }}
          >
            <Button themeType={'flat'}>
              <TextIconSpacing icon={<FontIcon>download</FontIcon>}>
                Checkout Minified
              </TextIconSpacing>
            </Button>
          </a>
        </div>

      </Fieldset>
    </div>

  )
}
