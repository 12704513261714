import React from 'react';
import { AccessDenied } from "./AccessDenied";
import { Overview } from "./Overview";
import { MediaOverlay } from "@react-md/media";
import { Button } from "@react-md/button";
import { useAddMessage } from "@react-md/alert";
import { FontIcon, TextIconSpacing } from "@react-md/icon";
// import { IMG_BASE_PATH } from "../../constants";

export function Access(
  {
    logic
  }
) {
  const addMessage = useAddMessage();
  const session = logic.getSession();
  const { auth: auth } = session.meta;
  let child;
  if (!auth) {
    child = <AccessDenied logic={logic} />
  } else if (auth === 'overview') {
    child = <Overview logic={logic} />
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#011834',
        '--rmd-form-text-border-color': '#fff',
        '--rmd-form-text-border-hover-color': 'rgba(255, 255, 255, 0.25)',
        // '--rmd-form-label-top-offset': '-10px',
        // '--rmd-form-floating-dense-top': '18px'
      }}
    >
      <MediaOverlay
        position={'absolute-center'}
        style={{
          backgroundColor: 'transparent',
          // textAlign: 'center',
          '--rmd-form-text-border-color': '#fff',
        }}
      >
        { child }
      </MediaOverlay>
      { auth === 'overview' && (
          <Button
            theme={'clear'}
            themeType={'flat'}
            style={{
              position: 'fixed',
              top: '12px',
              right: '180px',
              zIndex: 10000,
              color: '#fff'
            }}
            onClick={() => {
              session.setAuthCode(null, null)
                .subscribe(() => {
                  const msg = {
                    children: 'You have been logged out.'
                  };
                  if (session.userRoleId) {
                    session.logout().subscribe(() => {
                      addMessage(msg)
                    });
                  } else {
                    addMessage(msg);
                  }
                })
            }}
          >
            Logout
          </Button>
      )}
      <Button
        theme={'clear'}
        themeType={'flat'}
        style={{
          position: 'fixed',
          top: '12px',
          right: '12px',
          zIndex: 10000,
          color: '#fff'
        }}
        disabled={true}
      >
        <TextIconSpacing
          icon={<FontIcon>phone</FontIcon>}
        >
          702-885-5089
        </TextIconSpacing>
      </Button>
      {/*{ auth === 'overview' && (*/}
      {/*  <Button*/}
      {/*    themeType={'flat'}*/}
      {/*    style={{*/}
      {/*      position: 'fixed',*/}
      {/*      top: '12px',*/}
      {/*      right: '24px',*/}
      {/*      zIndex: 10000,*/}
      {/*    }}*/}
      {/*    onClick={() => {*/}
      {/*      const sess = logic.getSession();*/}
      {/*      sess.setState({ meta: { ...sess.meta, auth: 'app' }});*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <TextIconSpacing*/}
      {/*      icon={(*/}
      {/*        <img*/}
      {/*          src={`${IMG_BASE_PATH}/icons/icon-48x48.png`}*/}
      {/*          alt={'Attraction Marketing.com'}*/}
      {/*          width={'16px'}*/}
      {/*          height={'16px'}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    >*/}
      {/*      <TextIconSpacing*/}
      {/*        icon={<FontIcon>arrow_forward</FontIcon>}*/}
      {/*        iconAfter={true}*/}
      {/*      >*/}
      {/*        Access App*/}
      {/*      </TextIconSpacing>*/}
      {/*    </TextIconSpacing>*/}
      {/*  </Button>*/}
      {/*)}*/}
    </div>
  )
  return null;
}
