import React, {
  createElement,
  useEffect,
  useMemo,
  useState
} from 'react';
import { loadable } from "@isomorix/store";
import { IMG_BASE_PATH } from "../constants";
import { useLayoutContext } from "@isomorix/react-md-layout";
import { useEnsuredRef } from "@react-md/utils";
// import { snakeCaseToWords } from "@isomorix/utils";

const _staticStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute'
};

const videoChunkLoader = loadable(() => import('./ReactPlayer'));

const titles = [
  'How To Deal With Haters',
  'The 4-Letter Word That Will Lead To Huge Profits',
  'Building Rapport With Your Prospects Via Email',
  'Influencer Enrollment Method #1',
  'How To Get 90% Of Your Prospects To Enroll',
  'The 3 Phases Of Attraction Marketing Duplication',
  'How To Stop Imposter Syndrome',
  'What Two NYU Psychologists Discovered About How...',
  'Doing This One Thing Will Poison Your Business',
  '4 Words To Avoid If You Want To Succeed'
];

const getRandomConfig = (function() {
  let titleIdx = 0, darkIdx = 1;
  return () => {
    const resp = {
      preview: `${IMG_BASE_PATH}/vid/video_preview_dark_${darkIdx}.webp`,
      url: `https://dev-39.wistia.com/medias/qr9sodwdqn`,
      name: titles[titleIdx]
    };
    if (++titleIdx === titles.length) {
      titleIdx = 0;
    }
    if (++darkIdx > 4) {
      darkIdx = 1;
    }
    return resp;
  }
})();


// const previews = [
//   'breakout_app_builder_devs_preview.webp',
//   'breakout_cli_preview.webp',
//   'breakout_graphql_preview.webp',
//   'isx_presentation_devs_preview.webp',
//   'breakout_mutation_preview.webp',
//   'breakout_nginx_preview.webp',
//   'breakout_query_preview.webp'
// ];
// const urls = [
//   'nyan7mwi1l', // app builder
//   '3n4229v6ym', // cli
//   'v63h8l2gbl', // graphql
//   '73sl3pcebu', // main
//   '74gkwkya16', // mutation
//   't1z0m4qe4y', // nginx
//   'jlz47l2obs', // query
// ];
// let names;
// let randomIdx = 0;
// const getRandomConfig1 = () => {
//   if (!names) {
//     names = previews.map(p => snakeCaseToWords(p
//       .replace('.webp', '')
//       .replace('preview', ''), true)
//     );
//   }
//   let preview = previews[randomIdx];
//   if (!preview) {
//     randomIdx = 0;
//     preview = previews[randomIdx];
//   }
//   randomIdx++;
//   return {
//     preview: `${IMG_BASE_PATH}/vid/${preview}`,
//     url: `https://dev-39.wistia.com/medias/${urls[randomIdx - 1]}`,
//     name: names[randomIdx - 1]
//   };
// }

export const useRandomVideo = (forceReload, count) => {
  return useMemo(() => {
    if (count) {
      let i = 0;
      const resp = [];
      while(i < count) {
        resp.push(getRandomConfig());
        i++;
      }
      return resp;
    } else {
      return getRandomConfig();
    }
  }, [ forceReload, count ]);
}


const renderPreview = (name, preview) => (
  <div
    className={'responsive-video-player'}
    key={'videoPreview'}
    style={_staticStyle}
  >
    <img
      alt={`${name || 'unknown'} video`}
      width='100%'
      height='100%'
      src={preview}
    />
  </div>
);
// const FORCE_LIGHT = process.env.NODE_ENV !== 'production';
const FORCE_LIGHT = false;
let Video;
if (process.env.BROWSER) {
  const widthSetterOptions = { constrain: true };
  let noDelay;
  Video = React.memo(React.forwardRef(function Video(
    {
      name,
      preview,
      visibility: propsVisibility,
      containerRef: propContainerRef,
      staggerTimeout,
      ...props
    },
    propRef,
  ) {
    // const [ visibility, setVisibility ] = useState(propsVisibility || false);
    const [ ReactPlayer, setReactPlayer ] = useState(null);
    const [ ref, refHandler ] = useEnsuredRef(propRef);
    const [ containerRef, containerRefHandler ] = useEnsuredRef(propContainerRef);
    const { scrollWidth, scrollHeight } = useLayoutContext();
    useEffect(() => {
      const player = ref.current && ref.current.getInternalPlayer();
      const width = containerRef.current && containerRef.current.clientWidth;
      if (player && width > 0) {
        player.width(width, widthSetterOptions);
      }
    }, [ scrollWidth, scrollHeight, containerRef, ref ]);
    useEffect(() => {
      if (typeof noDelay !== 'boolean') {
        noDelay = false;
        setTimeout(() => {
          noDelay = true;
        }, 500);
      }
      if (noDelay && !staggerTimeout) {
        const sub = videoChunkLoader().subscribe(mod => {
          setReactPlayer(mod.ReactPlayer);
        });
        return () => sub.unsubscribe();
      }
      let sub;
      let timeout = setTimeout(() => {
        timeout = undefined;
        sub = videoChunkLoader().subscribe(mod => setReactPlayer(mod.ReactPlayer));
      }, staggerTimeout || 500);
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        } else if (sub) {
          sub.unsubscribe();
        }
      }
    }, [ staggerTimeout ]);
    props.ref = refHandler;
    let child;
    if (ReactPlayer) {
      if (FORCE_LIGHT) {
        props.light = true;
      } else if (typeof propsVisibility === 'boolean') {
        props.light = !propsVisibility;
      } else if (typeof props.light !== 'boolean') {
        props.light = true;
      }
      child = createElement(ReactPlayer, props);
    }
    return (
      <div ref={containerRefHandler} className={'responsive-video-container'}>
        { renderPreview(name, preview) }
        { child }
      </div>
    )
  }))

} else {
  Video = React.memo(React.forwardRef(function Video(
    {
      name,
      containerRef,
      preview
    },
    ref
  ) {
    return (
      <div ref={containerRef} className={'responsive-video-container'}>
        { renderPreview(name, preview) }
      </div>
    )
  }))
}

export const RandomVideo = React.memo(({ forceReload, ...props }) => {
  const { url, preview, name } = useRandomVideo(forceReload);
  props.url = url;
  props.preview = preview;
  props.name = name;
  return createElement(Video, props);
});

export { Video };

